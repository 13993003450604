<template>
	<resource-form :id="id" type="podcast" plural="podcasts" v-model="podcast"></resource-form>
</template>

<script>

import validationRules from "@/util/validation";
import {sync} from "vuex-pathify";
import resourceForm from '@/views/resources/Form'
import models from '@/models'

export default {
	name: "PodcastsForm",
	components: {resourceForm},
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		podcast: sync('podcasts/podcast'),
	},
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			if (this.isEdit) {
				this.$store.set('podcasts/getPodcast!', this.id)
			} else {
				this.podcast = new models.Resource('Podcast')
			}
		}
	}
}
</script>